import { Popover } from '@mui/material';
import React, { useContext } from 'react';

import {
  HISTORY_PROFILE,
  HISTORY_NOTE,
  HISTORY_SETTINGS,
  HISTORY_DEVICE,
  HISTORY_EVENTS,
  HISTORY_SUMMARY_REPORT,
  HISTORY_EOS_REPORT,
  HISTORY_EVENT_REPORT,
  DD_MMM_C_YYYY,
  HISTORY_CEMSUMMARY_REPORT,
  HISTORY_INSURANCE,
} from '../../../types/types';
import { ICONS } from '../../../utility/IconSelection';
import BIcon from '../../common/BIcon';
import BasePatientNotification from '../../patientNotifications/BasePatientNotification';
import {
  PatientArrhythmiaEventIconList,
  DeviceEventIconList,
} from '../../patientNotifications/PatientlistDeviceEvent';
import HistoryMoreInfo from './HistoryMoreInfo';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';

export const GetDescription = ({
  historyItem,
  openPopOverId,
  anchorEl,
  changePin,
  openDeviceEvent,
  handleClose,
  getReportLink,
  setReportViewed,
}) => {
  const PERMISSIONS = useContext(AccessPermissionsContext)?.permissions;
  switch (historyItem.type) {
    case HISTORY_PROFILE: {
      return (
        <>
          <span
            onClick={e => {
              openDeviceEvent(e, historyItem.id + 'profile');
            }}
          >
            <BIcon
              class='highlighticon'
              style={{
                verticalAlign: 'middle',
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
              icon={ICONS['MORE_INFO']}
            />
          </span>
          <Popover
            open={historyItem.id + 'profile' === openPopOverId}
            anchorEl={anchorEl}
            onClose={handleClose}
            style={{ border: 'solid grey' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <HistoryMoreInfo historyItem={historyItem} />
          </Popover>
          <span className='history-icon-desc'>
            {historyItem.descriptionText}
          </span>
        </>
      );
    }
    case HISTORY_INSURANCE: {
      return (
        <>
          <span
            onClick={e => {
              openDeviceEvent(e, historyItem.id + 'insurance');
            }}
          >
            <BIcon
              class='highlighticon'
              style={{
                verticalAlign: 'middle',
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
              icon={ICONS['MORE_INFO']}
            />
          </span>
          <Popover
            open={historyItem.id + 'insurance' === openPopOverId}
            anchorEl={anchorEl}
            onClose={handleClose}
            style={{ border: 'solid grey' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <HistoryMoreInfo historyItem={historyItem} />
          </Popover>
          <span className='history-icon-desc'>
            {historyItem.descriptionText}
          </span>
        </>
      );
    }
    case HISTORY_DEVICE: {
      return (
        <>
          <span
            onClick={e => {
              openDeviceEvent(e, historyItem.id + 'device');
            }}
          >
            <BIcon
              class='highlighticon'
              style={{
                verticalAlign: 'middle',
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
              icon={ICONS['MORE_INFO']}
            />
          </span>
          <Popover
            open={historyItem.id + 'device' === openPopOverId}
            anchorEl={anchorEl}
            onClose={handleClose}
            style={{ border: 'solid grey' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <HistoryMoreInfo historyItem={historyItem} />
          </Popover>
          <span className='history-icon-desc'>
            {historyItem.descriptionText}
          </span>
        </>
      );
    }
    case HISTORY_SETTINGS: {
      return (
        <>
          <span
            onClick={e => {
              openDeviceEvent(e, historyItem.id + 'settings');
            }}
          >
            <BIcon
              class='highlighticon'
              style={{
                verticalAlign: 'middle',
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
              icon={ICONS['MORE_INFO']}
            />
          </span>
          <Popover
            open={historyItem.id + 'settings' === openPopOverId}
            anchorEl={anchorEl}
            onClose={handleClose}
            style={{ border: 'solid grey' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <HistoryMoreInfo historyItem={historyItem} />
          </Popover>
          <span className='history-icon-desc'>
            {historyItem.descriptionText}
          </span>
        </>
      );
    }
    case HISTORY_NOTE: {
      return (
        <>
          {historyItem.isPinned ? (
            <span onClick={() => changePin(historyItem.id)}>
              <BIcon
                class='highlighticon pinnedNote'
                style={{
                  verticalAlign: 'middle',
                  width: '25px',
                  height: '25px',
                  cursor: 'pointer',
                }}
                icon={ICONS['PIN_ON']}
              />
            </span>
          ) : (
            <span onClick={() => changePin(historyItem.id)}>
              <BIcon
                class='highlighticon unpinnedNote'
                style={{
                  verticalAlign: 'middle',
                  width: '25px',
                  height: '25px',
                  cursor: 'pointer',
                }}
                icon={ICONS['PIN_OFF']}
              />
            </span>
          )}

          <span className='history-icon-desc'>
            {historyItem.descriptionText}
          </span>
        </>
      );
    }
    case HISTORY_EVENTS: {
      return (
        <>
          <div style={{ display: 'inline-flex', justifyContent: 'center' }}>
            <PatientArrhythmiaEventIconList
              eventList={historyItem.events.eventList}
            />
            <DeviceEventIconList
              eventList={historyItem.events.patientEventList}
            />
          </div>
        </>
      );
    }
    case HISTORY_EOS_REPORT:
    case HISTORY_CEMSUMMARY_REPORT:
    case HISTORY_SUMMARY_REPORT: {
      const reportLink = historyItem.link;
      return (
        <>
          <span
            style={{ cursor: reportLink ? 'pointer' : '' }}
            onClick={async e => {
              try {
                if (reportLink === null) return;
                const data = await getReportLink({
                  variables: { reportFilename: reportLink },
                });
                let reportURL = data?.data?.getReportLink;
                window.open(reportURL, '_blank');
              } catch (error) {
                console.error('Error fetching Report Link:', error);
              }
            }}
          >
            <BIcon
              class='highlighticon'
              style={{
                verticalAlign: 'middle',
                width: '25px',
                height: '25px',
                fill: reportLink ? '' : 'rgba(26, 28, 28, 0.49)',
              }}
              icon={ICONS['PDF2']}
            />
          </span>
          <span className='history-icon-desc'>
            {historyItem.descriptionText}
          </span>
        </>
      );
    }
    case HISTORY_EVENT_REPORT: {
      const reportLink = historyItem.link;
      return (
        <>
          <span
            style={{ cursor: 'pointer' }}
            onClick={e => {
              getReportLink({ variables: { reportFilename: reportLink } }).then(
                (data: any) => {
                  let reportURL = data?.data?.getReportLink;
                  if (PERMISSIONS.includes('ClinicianAccess')) {
                    setReportViewed({
                      variables: { reportId: historyItem?.reportId },
                    }).then(reportViewData => {
                      if (!reportViewData?.data?.setReportViewed) {
                        console.error('Could not set the report as viewed');
                      }
                    });
                  }
                  window.open(reportURL, '_blank');
                }
              );
            }}
          >
            <BIcon
              class='highlighticon'
              style={{
                verticalAlign: 'middle',
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
              icon={ICONS['PDF1']}
            ></BIcon>
          </span>
          <span className='history-icon-desc'>
            {historyItem.descriptionText}
          </span>
        </>
      );
    }
    default:
      return <span>Error!</span>;
  }
};
