import gql from 'graphql-tag';

export const DEVICE_DATA = gql`
  query getDeviceDataFromPatientId($patientId: String) {
    patient(id: $patientId)
    {
      devices {
        id
        deviceId
        prescribing_provider
        active
        referring_provider
        prescribed_wear_time_days
        type
        application
        activation_status
        deviceStatus {
          last_transmission_timestamp
          battery_level_percentage
          recording_started_at
        }
        deviceStatusWithFlagsView {
          device_status_with_flags
        }
        deviceActivations(orderByDesc: timestamp) {
          timestamp
        }
      }
      organization {
        id
      }
    }
  }
`;

// TODO add application field to this mutation
export const UPDATE_DEVICE_DATA = gql`
  mutation createOrUpdateDevice(
    $deviceId: String
    $implantId: String
    $prescribing_provider: String
    $referring_provider: String
    $patientId: String
    $prescribed_wear_time_days: Int
    $application: String
    $type: String
  ) {
    createOrUpdateDevice(
      deviceId: $deviceId
      implantId: $implantId
      prescribing_provider: $prescribing_provider
      referring_provider: $referring_provider
      patientId: $patientId
      prescribed_wear_time_days: $prescribed_wear_time_days
      application: $application
      type: $type
    ) {
      activation_status
    }
  }
`;

export const Q_DEVICE_EVENTS = gql`
  query getDeviceEvent($id: String) {
    deviceEventGroups(id: $id) {
      id
      start_time
      end_time
      deviceEvents {
        id
        value
        device_id
        deviceEventSetting {
          severity
          deviceEventSettingThresholds {
            id
            deviceEventSettingThresholdType {
              name
              min
              max
              unit
              default_value
              reference_id
              display_order
            }
          }
          deviceEventSettingType {
            name
            abbr
            default_severity
          }
        }
      }
    }
  }
`;

export const createDevice = gql`
  mutation createDeviceSubscription{
    createDeviceSubscription(deviceId: $deviceId) {
      liveLink
    }
  }
`;

export const ACTIVATE_DEVICE = gql`
  mutation activateDevice(
    $deviceId: String
    $prescribing_provider: String
    $implantId: String
    $referring_provider: String
    $patientId: String
    $test_override: Boolean
  ) {
    activateDevice(
      deviceId: $deviceId
      prescribing_provider: $prescribing_provider
      implantId: $implantId
      referring_provider: $referring_provider
      patientId: $patientId
      test_override: $test_override
    ) {
      device {
        id
        activation_status
      }
      timestamp
    }
  }
`;

export const DEACTIVATE_DEVICE = gql`
  mutation deactivateDevice($deviceId: String, $patientId: String) {
    deactivateDevice(deviceId: $deviceId, patientId: $patientId) {
      device {
        deviceId
        activation_status
      }
    }
  }
`;

export const DELETE_DEVICE = gql`
  mutation deleteDevice($deviceId: String!) {
    deleteDevice(deviceId: $deviceId)
  }
`;

export const TOGGLE_DEVICE_SUBSCRIPTION = gql`
  mutation toggleDeviceSubscription($deviceId: String!) {
    toggleDeviceSubscription(deviceId: $deviceId)
  }
`;

export const patient = gql`
  query patient{
    patient(patientId: $patientId) {
      id
      devices {
        id
        deviceId
        active
        deviceActivation {
          activationTimestamp
        }
      }
    }
  }
`;
// start local  mutations
export const M_DEVICE_ACTIVATE = gql`
  mutation setDevicePrompt($id: Boolean!) {
    setDevicePrompt(devicePrompt: $id) @client
  }
`;

export const Q_DEVICE_ACTIVATE_PROMPT = gql`
  query devicePrompt{
    devicePrompt @client
  }
`;
// end local mutations
