import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Formik } from 'formik';
import { formReportStruct } from '../../../utility/helper/history/HistoryOperations';
import { generateID, getPrettyDate } from '../../../utility/utilityFunctions';
import BToastMessage from '../../common/ToastMessage';
import { commonData } from './../../../data/CommonData';
import { AlertColor } from '@mui/material';
import {
  Backdrop,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { ICONS } from '../../../utility/IconSelection';
import { useMutation, useQuery } from '@apollo/client';
import {
  REPORTS,
  GET_REPORT_LINK,
  SET_REPORT_VIEWED,
} from '../../../data/queries/report';
import { orderBy as lodashOrderBy, trim as lodashTrim } from 'lodash';
import BDropDown from '../../common/BDropDown';
import BButton from '../../common/BButton';
import BTextField from '../../common/BTextField';
import {
  ADD_TECHNICIAN_NOTES,
  TOGGLE_PINNED_TECHNICIAN_NOTES,
  DELETE_PATIENT,
  SELECTED_PATIENT_ID,
} from '../../../data/queries/patient';
import {
  REPORT_TABLE_HEADER,
  DF_DD_MMM_YYYY_HH_COL_SMSM,
  PATIENT_HISTORY_EVENT_MAPPING,
  columnsSortHistory,
  HISTORY_EOS_REPORT,
  HISTORY_SUMMARY_REPORT,
} from '../../../types/types';
import { GetDescription } from './PatientHistoryList';
import BIcon from '../../common/BIcon';
import BConfirm from '../../common/BConfirm';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';
import { useSendAuditTrailLogMessage } from '../../../data/queries/auditTrailLogMessage';

interface IHistoryItem {
  id?: any;
  type?: any;
  createdAt?: any;
}

// TODO type this properly - IPatientDetails ?
interface IPatientRegistrationHistory {
  patientDetails: any;
  params: any;
  gotoHome: any;
  reflectUpdatedPatientChanges: any;
}

// Reference: Requirement #24647: Summary report filter should also show EOS summary report - hence custom compare function
export const compareHistoryFilterValues = (value, selectedValue) => {
  if (selectedValue === HISTORY_SUMMARY_REPORT) {
    // also include the EOS summary reports list
    return value === HISTORY_SUMMARY_REPORT || value === HISTORY_EOS_REPORT;
  } else {
    // for values other than summary report, simply compare value and selectedValue
    return value === selectedValue;
  }
};

// const useStyles = stylesPatientList;
const PatientRegistrationHistory = (props: IPatientRegistrationHistory) => {
  // const classes = useStyles();
  // Start Methods
  const addTechNote = values => {
    // graphql createReport call for adding the report data into database
    addTechnicianNote({ variables: values }).then(
      res => {
        fetchMore({
          updateQuery: (p, { fetchMoreResult }) => {
            if (fetchMoreResult.patient) {
              const hList = formReportStruct(
                fetchMoreResult.patient.history,
                props.patientDetails,
                fetchMoreResult.patient.devices
              );
              setReportList(hList.historyList);
            }
          },
        });
        setAddReportSuccessMessage({
          visible: true,
          type: 'success',
          msg: commonData.general.saveToastMessage,
        });
        sendAuditMessage({
          action: 'DATA_SAVE',
          phi_flag: true,
          description: `Technician note added ${values.note} for patientId ${values.patientId}`,
        });
      },
      error => {
        setAddReportSuccessMessage({
          visible: true,
          type: 'error',
          msg: commonData.general.saveErrorToastMessage,
        });
        console.log('Error while adding the Technician notes: ', error);
        sendAuditMessage({
          action: 'DATA_SAVE_FAILURE',
          phi_flag: true,
          description: `Failed to add technician note ${values.note} for patientId ${values.patientId}`,
        });
      }
    );
    // TODO: Reset the form
  };
  interface Data {
    record: string;
    datetime: any;
  }
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const { sendAuditMessage } = useSendAuditTrailLogMessage();

  // End Methods
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const { canAccessTechNotes } = USER_ACCESS;

  // Start set states
  const [selectedValue, setSelectedValue] = useState(
    props?.params?.filter || '0'
  ); // All reports
  const [addReportSuccessMessage, setAddReportSuccessMessage] = useState({
    visible: false,
    type: '',
    msg: '',
  });
  const [reportList, setReportList] = useState(null);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState<any>('desc');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopOverId, setPopOverId] = useState(null);
  const [unsaveConfirm, setUnsaveConfirm] = useState({ open: false });
  // End set states

  // start fetch data, mutate data
  const {
    loading: loadingSelectedPatient,
    error: errorSelectedPatient,
    data: { selectedPatientId } = { selectedPatientId: null },
  } = useQuery(SELECTED_PATIENT_ID);
  const { loading, error, data, fetchMore } = useQuery(REPORTS, {
    variables: { patient_id: selectedPatientId },
    fetchPolicy: 'network-only',
  }); // TODO: change the fetchpolicy once subscription model is implemented
  const [addTechnicianNote] = useMutation(ADD_TECHNICIAN_NOTES);
  const [toggleTechnicianNotes] = useMutation(TOGGLE_PINNED_TECHNICIAN_NOTES);
  const [getReportLink] = useMutation(GET_REPORT_LINK);
  const [deletePatient] = useMutation(DELETE_PATIENT);
  const [setReportViewed] = useMutation(SET_REPORT_VIEWED);
  // End fetch data, mutate data

  useEffect(() => {
    // todo data.devices
    if (data) {
      if (data.patient) {
        const hList = formReportStruct(
          data.patient.history,
          props.patientDetails,
          data.patient.devices
        );
        setReportList(hList.historyList);
        if (data.patient.devices?.length > 0) {
          sendAuditMessage({
            action: 'DATA_ACCESS',
            phi_flag: true,
            description: `Loaded device ${data?.patient?.devices[0]?.deviceId} history`,
          });
        }
      }
    }
  }, [data]);

  if (loadingSelectedPatient || loading) return <p> Loading... </p>;
  if (error || errorSelectedPatient)
    return <p> Error occurred, please contact the Support Team! </p>;

  // handle onChange event of the dropdown
  const handleHistoryTypeChange = value => {
    setSelectedValue(value.target.value);
  };

  const openDeviceEvent = (event: React.MouseEvent<HTMLElement>, id) => {
    setPopOverId(id);
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setPopOverId(null);
  };

  const changePin = historyValue => {
    // call API to toggle pin TODO error handling
    toggleTechnicianNotes({ variables: { historyId: historyValue } }).then(
      res => {
        //res.data has list of Technician notes with the pinned order
        const newArr = [...reportList];

        // converting to obj for easy lookout
        const objRes = res.data.togglePinned.reduce((obj, item) => {
          obj[item.id] = item.pinned_order;
          return obj;
        }, {});
        newArr.map((item, i) => {
          if (item.type == 'note') {
            newArr[i]['order'] = objRes[item.id] || -1; // change order as received from the API response otherwise give default value -1
            newArr[i]['isPinned'] = newArr[i]['order'] === -1 ? false : true; // All records which are not pinned has -1 value
          }
        });
        setReportList(newArr);

        // reflect changes to patient registration header notes
        props.reflectUpdatedPatientChanges();
        sendAuditMessage({
          action: 'RESOURCE_UPDATE',
          phi_flag: true,
          description: `Toggled pinned for historyId ${historyValue}`,
        });
      }
    );
  };

  const sortHistoryColums: any = arr => {
    if (selectedValue === 'note') {
      // if filter is on Technician notes
      return lodashOrderBy(arr, ['order', orderBy], ['desc', order]);
    } else {
      return lodashOrderBy(arr, [orderBy], [order]);
    }
  };

  if (!reportList) return <p>No data received</p>;
  return (
    <>
      <BConfirm
        title={'The patient details will be deleted forever.'}
        children={'Do you still want to proceed?'}
        open={unsaveConfirm.open}
        setOpen={setUnsaveConfirm}
        onConfirm={() => {
          deletePatient({ variables: { patientId: selectedPatientId } })
            .then(() => {
              sendAuditMessage({
                action: 'RESOURCE_DELETION',
                phi_flag: true,
                description: `Deleted patientId: ${selectedPatientId}`,
              });
              props.gotoHome();
            })
            .catch(error => {
              console.error('Error occured:', error);
              sendAuditMessage({
                action: 'RESOURCE_DELETION_FAILURE',
                phi_flag: true,
                description: `Failed to delete patientId ${selectedPatientId} due to error ${error}`,
              });
            });
        }}
        severity='warning'
      />
      {/* Start Report Add  */}
      <Formik
        initialValues={{ note: '' }}
        onSubmit={(values, { resetForm }) => {
          // add patient id to the values to send to store in database
          values['patientId'] = selectedPatientId;
          addTechNote(values);
          resetForm();
        }}
      >
        {({ handleChange, values, handleSubmit, setFieldValue }) => (
          <>
            <Backdrop open={openPopOverId !== null} />

            <Grid container spacing={2}>
              {USER_ACCESS.canDeletePatient ? (
                <>
                  <Grid item md={10} lg={10}></Grid>
                  <Grid item md={2} lg={2}>
                    <BButton
                      caption='Delete Patient'
                      color='secondary'
                      clickEvent={() => {
                        setUnsaveConfirm({ open: true });
                      }}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item md={12} lg={2}>
                <span
                  style={{
                    float: 'right',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <BToastMessage
                    id={generateID('toast', 'pr_history', 'notification_msg')}
                    open={addReportSuccessMessage.visible}
                    close={() => {
                      setAddReportSuccessMessage({
                        visible: false,
                        type: '',
                        msg: '',
                      });
                    }}
                    style={{ marginTop: '94px' }}
                    severity={addReportSuccessMessage.type as AlertColor}
                    msg={addReportSuccessMessage.msg}
                  />
                </span>
              </Grid>
            </Grid>
            <form>
              <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                <Grid item xs={2}>
                  <BDropDown
                    id={generateID('drpdwn', 'pr_history', 'history_events')}
                    label='Filter'
                    value={selectedValue}
                    menuItems={USER_ACCESS.historyFilter}
                    shrink={true}
                    labelWidth={55}
                    fullWidth={true}
                    handleChange={val => handleHistoryTypeChange(val)}
                  />
                </Grid>
                {canAccessTechNotes ? (
                  <>
                    <Grid
                      item
                      xs={2}
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <BButton
                        style={{ float: 'right' }}
                        id={generateID(
                          'btn',
                          'pr_history',
                          'add_technician_note'
                        )}
                        disabled={lodashTrim(values['note']) === ''}
                        type='blue'
                        clickEvent={e => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                        caption='ADD NOTES'
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <BTextField
                        id={generateID(
                          'input',
                          'pr_history',
                          'technician_note'
                        )}
                        name='note'
                        label='Technician Notes'
                        value={values.note}
                        required={false}
                        shrink={true}
                        fullWidth={true}
                        handleChange={handleChange}
                        additionalProps={{
                          endAdornment: (
                            <InputAdornment
                              style={{
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                width: '20px',
                              }}
                              position='end'
                              onClick={() => {
                                setFieldValue('note', '');
                              }}
                            >
                              X
                              {/* <BIcon style={{ verticalAlign: 'middle' }} icon={ICONS["SETTINGS"]} /> */}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </form>
            {/* End Report Add  */}
            {/* Start Report List  */}
            <TableContainer>
              <Table id={generateID('table', 'pr_history', 'history_list')}>
                <TableHead id='theader-report-list'>
                  <TableRow className='patient-list-title'>
                    {REPORT_TABLE_HEADER.map((headerItem: any, i) => {
                      return (
                        <TableCell
                          key={'history-list-header-' + i}
                          style={{
                            minWidth: headerItem.colLength,
                            padding: '5px',
                          }}
                          className={headerItem.class}
                          sortDirection={
                            orderBy === headerItem.label ? order : false
                          }
                        >
                          {columnsSortHistory.includes(headerItem.id) ? (
                            <TableSortLabel
                              active={orderBy === headerItem.id}
                              direction={
                                orderBy === headerItem.id ? order : 'asc'
                              }
                              onClick={createSortHandler(headerItem.id)}
                            >
                              {headerItem.label}
                            </TableSortLabel>
                          ) : (
                            <span>{headerItem.label}</span>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody id='tbody-report-list'>
                  {reportList &&
                    sortHistoryColums(reportList)
                      .filter(
                        (historyItem: IHistoryItem) =>
                          selectedValue === '0' ||
                          compareHistoryFilterValues(
                            historyItem.type,
                            selectedValue
                          )
                      )
                      .map((reportItem: IHistoryItem, i) => {
                        return (
                          <Fragment key={'history-list-item-' + i}>
                            <TableRow id={`tl-${reportItem.id}`}>
                              {/* Column1 */}
                              <TableCell
                                className={'reportlist-type'}
                                style={{ padding: '5px' }}
                              >
                                <Typography>
                                  <BIcon
                                    style={{
                                      verticalAlign: 'middle',
                                      width: '25px',
                                      height: '25px',
                                    }}
                                    icon={ICONS[reportItem.type]}
                                  />
                                  {
                                    PATIENT_HISTORY_EVENT_MAPPING[
                                      reportItem.type
                                    ]
                                  }
                                </Typography>
                              </TableCell>
                              {/* Column2 */}
                              <TableCell
                                className={'reportlist-date'}
                                style={{ padding: '10px' }}
                              >
                                <Typography>
                                  {getPrettyDate(
                                    reportItem.createdAt,
                                    DF_DD_MMM_YYYY_HH_COL_SMSM,
                                    true
                                  )}
                                </Typography>
                              </TableCell>
                              {/* Column3 */}
                              <TableCell
                                className={'reportlist-userid'}
                                style={{ padding: '10px' }}
                              >
                                <Typography>User ID</Typography>
                              </TableCell>
                              {/* Column4 */}
                              <TableCell>
                                <Typography>
                                  <GetDescription
                                    historyItem={reportItem}
                                    openPopOverId={openPopOverId}
                                    anchorEl={anchorEl}
                                    changePin={changePin}
                                    openDeviceEvent={openDeviceEvent}
                                    handleClose={handleClose}
                                    getReportLink={getReportLink}
                                    setReportViewed={setReportViewed}
                                  />
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Formik>
    </>
  );
};

export default PatientRegistrationHistory;
