import BTextField from '../../common/BTextField';
import { generateID } from '../../../utility/utilityFunctions';
import { AlertColor, Grid } from '@mui/material';
import BSaveCancel from '../../common/BSaveCancel';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_UPDATE_INSURANCE,
  GET_INSURANCE,
} from '../../../data/queries/insurance';
import { useEffect, useState } from 'react';
import BToastMessage from '../../common/ToastMessage';
import { commonData } from '../../../data/CommonData';
import { SELECTED_PATIENT_ID } from '../../../data/queries/patient';
import BSpinner from '../../common/BSpinner';

const PatientRegistrationInsurance = () => {
  // query to fetch initial values
  const [insuranceData, setInsuranceData] = useState(null);
  const [updateInsurance] = useMutation(CREATE_UPDATE_INSURANCE);
  const {
    loading: loadingSelectedPatient,
    error: errorSelectedPatient,
    data: { selectedPatientId } = { selectedPatientId: null },
  } = useQuery(SELECTED_PATIENT_ID);
  let { loading, error, data } = useQuery(GET_INSURANCE, {
    variables: { patientId: selectedPatientId },
    skip: !selectedPatientId,
  });

  useEffect(() => {
    if (!data) return;
    if (!data.patient) return;
    if (data.patient.insurance && data.patient.insurance.length === 1) {
      setInsuranceData(data.patient.insurance[0]);
    } else {
      setInsuranceData({
        insurance_provider: '',
        member_id: '',
      });
    }
  }, [data]);

  const [savedChanges, setSavedChanges] = useState({
    apiCall: false,
    type: '',
    msg: '',
  });
  const submitInsurance = async (values, resetForm) => {
    let insuranceDetails = {
      patientId: selectedPatientId,
      member_id: values.memberId,
      insurance_provider: values.insuranceProvider,
    };
    const response = await updateInsurance({
      variables: { input: insuranceDetails },
    });

    if (response?.data?.updateInsurance) {
      setSavedChanges({
        apiCall: true,
        type: 'success',
        msg: commonData.general.saveToastMessage,
      });
      resetForm({ values: values });
    }
  };
  if (loading) return <BSpinner text={'Loading Insurance information...'} />;
  if ((selectedPatientId && insuranceData) || !selectedPatientId) {
    return (
      <>
        <BToastMessage
          id={generateID('toast', 'pr_insurance', 'notification_msg_down')}
          open={savedChanges.apiCall}
          close={() => {
            setSavedChanges({
              apiCall: false,
              type: '',
              msg: '',
            });
          }}
          style={{ position: 'inherit', marginRight: '5px' }}
          severity={savedChanges.type as AlertColor}
          msg={savedChanges.msg}
        />

        <Formik
          initialValues={{
            insuranceProvider: insuranceData?.insurance_provider,
            memberId: insuranceData?.member_id,
          }}
          onSubmit={(values, { resetForm }) => {
            submitInsurance(values, resetForm);
          }}
        >
          {({
            handleChange,
            handleBlur,
            setFieldValue,
            submitForm,
            dirty,
            values,
          }) => (
            <form
              autoComplete='off'
              onSubmit={e => {
                e.preventDefault();
                submitForm();
              }}
            >
              <Grid container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <BTextField
                        label='Insurance Provider'
                        name='patient.insurance_provider'
                        id={generateID(
                          'input',
                          'pr_insurance',
                          'insurance_provider'
                        )}
                        value={values.insuranceProvider}
                        shrink={true}
                        fullWidth={true}
                        handleChange={e => {
                          handleChange(e);
                          setFieldValue('insuranceProvider', e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <BTextField
                        label='Member ID'
                        name='patient.member_id'
                        id={generateID('input', 'pr_insurance', 'member_id')}
                        value={values.memberId}
                        shrink={true}
                        fullWidth={true}
                        handleChange={e => {
                          handleChange(e);
                          setFieldValue('memberId', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <span
                        style={{
                          float: 'right',
                        }}
                      >
                        <BSaveCancel
                          saveId={generateID('btn', 'pr_insurance', 'save_btm')}
                          cancelId={generateID(
                            'btn',
                            'pr_insurance',
                            'cancel_btm'
                          )}
                          saveDisabled={!dirty}
                          cancelEvent={() => {}}
                        />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </>
    );
  }
};

export default PatientRegistrationInsurance;
